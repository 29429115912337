<template>
  <div class="about">
    <img
      class="banner"
      :index="62"
      src="@/assets/images/about1/img_83821_0_0.png"
    />
    <div v-if="true" class="content" :index="60">
      <div v-if="true" class="block-1">
        <div class="about-container">
          <a class="tag-2" :index="42" href="#intro">平台简介 </a>
          <div class="vertical-line-3" :index="36" />
          <a class="label-2" :index="41" href="#service">产品与服务 </a>
        </div>
      </div>
      <div class="block-2">
        <div class="divider-wrapper">
          <img
            class="divider"
            :index="44"
            src="@/assets/images/about1/img_83821_0_1.png"
          />
          <img
            class="horizontal-line"
            :index="43"
            src="@/assets/images/about1/img_83821_0_2.png"
          />
        </div>
        <span id="intro" class="word-2" :index="40">平台简介 </span>
        <div class="section-wrapper" :index="35">
          <span class="section" :index="33"
            >名门家谱介绍
            <br /><br />
            “名门家谱”由曾子文化、家谱数字化倡导者刘鑫和数十位专业从事修谱服务10年以上的修谱专家共同发起组建。专业从事互联网修谱服务（纸网谱同修）、修谱软件和家族数字云平台的研发与销售，为各个姓氏家谱数字化、姓氏内容产品开发、家族文化的建设、宗亲凝聚和家谱有序实名制数据应用提供综合解决方案。名门家谱科技现对外直接投资元未来科技、乐和旅居、苗向缘文化等。
            <br /><br />
            名门家谱收集了1949年以前的老谱资料二十五万余册，覆盖500多个姓氏，补齐各姓氏修谱无老谱查询的短板，解决家族寻根查谱四处奔波的痛点，让寻根查谱的花费节省80%以上，为各个姓氏家族提供所有老谱数据输出服务，支持各个姓氏自建寻根查谱网络平台；同时名门家谱联合文化领域专家人才资源、图书馆和文旅服务等机构，为各个姓氏家族的家族文化建设与梳理、寻根文旅线路设计、姓氏文创产品设计与生产，提供培训及点对点支持服务，形成了以家谱数字化为引导、家族内容产品设计和生产为依托、家族寻根文旅活动为交互的立体家族文化建设与传播矩阵。 
            <br /><br />
            截止到2021年底，名门家谱在全国开设县级业务分公司21家，合作设立家族数字云平台15个，在线注册修谱师1300人，服务30000多个家族。 
            <br /><br />
            名门家谱数字云平台有以下特点：
            <br /><br />
            简单：操作简单，用手机就可以修谱、查谱、寻根以及与亲友交流。
            <br /><br />
            智能：平台拥有强大的搜索功能，知道连续两代人的信息，就可以精准匹配搜索，寻根查谱，输入字辈、自己的姓名等，就可以全平台模糊匹配搜索查谱；同时，平台具备智能排版功能、直系谱、家庭谱、房谱、支谱、族谱等可以一键生成、自动排版导出可印刷文件；支持数据调用，无论是素材还是家谱数据，都支持一键调用，让修谱成本降低80%，修谱效率提升百倍以上。不同家谱之间连续两带人同名同姓同出生日期系统会自动提示合谱，各支各房完成支房谱的同时，族谱，甚至通谱就基本形成了。
            <br /><br />
            便捷:有新增人丁可以随时上谱，发现信息疏漏可以及时修正，只要有网络就可以查谱、寻根与亲友交流。个人重要时刻可以随手记录，支持一键导出个人年谱。个人有什么需求可以直接分享到家族圈，可以第一时间获得家人的支持！
          </span>
        </div>
        <div
          style="width: 91%; display: flex; justify-content: space-between; margin-top: 20px; padding-left: 30px;box-sizing: border-box"
        >
          <img
            class="large-icon"
            :index="16"
            src="@/assets/images/about1/img_83821_0_8.png"
          />
          <img
            class="large-icon-1 large-icon"
            :index="19"
            src="@/assets/images/about1/img_83821_0_7.png"
          />
          <img
            class="large-icon-2 large-icon"
            :index="22"
            src="@/assets/images/about1/img_83821_0_4.png"
          />
          <img
            class="large-icon-3 large-icon"
            :index="21"
            src="@/assets/images/about1/img_83821_0_5.png"
          />
          <img
            class="large-icon-4 large-icon"
            :index="20"
            src="@/assets/images/about1/img_83821_0_6.png"
          />
        </div>
        <div class="container-2">
          <div class="container-inner">
            <span class="tag-3" :index="32">企业使命 </span>
            <span class="subtitle" :index="31">家风传承 谱泽后世 </span>
          </div>
          <div class="container-inner" style="margin-left: -10px;">
            <span class="organization" :index="30">企业愿景 </span>
            <span class="company" :index="29"
              >让优秀家族文化插上互联网的翅膀
            </span>
          </div>
          <div class="container-inner" style="margin-left: -10px;">
            <span class="label-3" :index="28">企业宗旨 </span>
            <span class="desc" :index="27">专注谱学 传承姓氏文化 </span>
          </div>
          <div class="container-inner" style="margin-left: 0px;">
            <span class="word-3" :index="26">企业目标 </span>
            <span class="summary" :index="25"
              >让每个家庭都拥有属于自己的历史
            </span>
          </div>
          <div class="container-inner">
            <span class="tag-4" :index="24">企业口号 </span>
            <span class="label-4" :index="23">让传统家谱活起来</span>
          </div>
        </div>
      </div>
      <div v-if="true" class="block-3">
        <img
          class="divider-1"
          :index="34"
          src="@/assets/images/about1/img_83821_0_3.png"
        />
        <span id="service" class="word-4" :index="39">产品与服务 </span>
        <img
          class="layer-2"
          :index="15"
          src="@/assets/images/about1/img_83821_0_9.jpeg"
        />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped>
/* .about {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  width: 1920px;
  height: 2135px;
} */

.about {
  width: 1200px;
  margin: 0 auto;
}

.main {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  /* margin-top: 53px; */
  /* margin-right: 268px; */
  /* margin-left: 359px; */
}

.banner {
  position: relative;
  width: 1202px;
  height: 302px;
}

.content {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 5px;
  background-color: #ffffff;
  width: 1202px;
  overflow: auto;
}

.block-1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  height: 57px;
  line-height: 14px;
  white-space: nowrap;
}

.about-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-top: 22px;
  margin-left: 41px;
}

.tag-2 {
  margin-top: -4px;
  margin-right: 18px;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  color: #c90a15;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.vertical-line-3 {
  margin-right: 19px;
  background-color: #efefef;
  width: 3px;
  height: 22px;
  overflow: auto;
  line-height: NaNpx;
  white-space: normal;
}

.label-2 {
  margin-top: -3px;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.block-2 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-top: 3px;
  width: 1202px;
  margin-bottom: 20px;
  /* height: 835px; */
}

.divider-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-top: -3px;
  width: 1162px;
  height: 3px;
}

.divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 1162px;
  height: 3px;
}

.horizontal-line {
  position: absolute;
  top: -2px;
  left: 39px;
  width: 20px;
  height: 4px;
}

.word-2 {
  position: relative;
  margin-top: 26px;
  max-width: 1190px;
  height: 25px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.section-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  border-radius: 4px;
  background-color: #f7f7f7;
  width: 1162px;
  /* height: 182px; */
  overflow: auto;
  line-height: 30px;
  flex: none;
  padding: 10px 0;
}

.section {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 5;
  margin-top: 1px;
  width: 1120px;
  /* height: 151px; */
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: normal;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.container-1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  margin-top: 22px;
}

.container-inner-i0 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 18px;
  width: 572px;
}

.overlayer {
  position: relative;
  border-radius: 4px;
  background-color: #333333;
  width: 572px;
  height: 302px;
  overflow: auto;
}

.info {
  position: relative;
  align-self: center;
  margin-top: 20px;
  text-align: left;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.large-icon {
  width: 60px;
  height: 60px;
}

.container-inner-i1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: -2px;
  width: 572px;
}

.layer-1 {
  position: relative;
  border-radius: 4px;
  background-color: #333333;
  width: 572px;
  height: 302px;
  overflow: auto;
}

.meta {
  position: relative;
  align-self: center;
  margin-top: 20px;
  text-align: left;
  text-decoration: none;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.large-icon-1 {
  width: 60px;
  height: 60px;
}

.container-2 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 7px;
  line-height: 30px;
  margin-left: 20px;
  width: 93%;
  justify-content: space-between;
}

.container-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 120px;
  text-align: center;
  /* margin-top: 1px; */
  /* margin-right: 100px; */
}

.tag-3 {
  position: relative;
  max-width: 107px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.subtitle {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 2;
  position: relative;
  /* width: 119px; */

  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: normal;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-i0-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1px;
  /* margin-right: 119px; */
  /* margin-right: 10px; */
  position: relative;
  left: -13px;
}

.organization {
  position: relative;
  max-width: 131px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.company {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 3;
  position: relative;
  width: 147px;
  height: 60px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-i1-1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  left: -11px;
  /* margin-right: 102px; */
}

.label-3 {
  position: relative;
  /* margin-left: 37px; */
  max-width: 80px;
  height: 31px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.desc {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 3;
  position: relative;
  width: 154px;
  height: 60px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-i2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1px;
  position: relative;
  left: -20px;
  /* margin-right: 132px; */
}

.word-3 {
  position: relative;
  /* margin-left: 37px; */
  max-width: 80px;
  height: 30px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.summary {
  webkit-box-orient: vertical;
  display: -webkit-inline-box;
  webkit-line-clamp: 2;
  position: relative;
  width: 154px;
  height: 60px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  letter-spacing: 0px;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.container-inner-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1px;
}

.tag-4 {
  position: relative;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

.label-4 {
  position: relative;
  text-align: left;
  text-decoration: none;
  letter-spacing: 0px;
  white-space: pre;
  color: #656565;
  font-family: "MicrosoftYaHei";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.large-icon-2 {
  width: 74px;
  height: 74px;
}

.large-icon-3 {
  width: 74px;
  height: 74px;
}

.large-icon-4 {
  width: 74px;
  height: 74px;
}

.block-3 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  margin-top: 3px;
}

.divider-1 {
  position: relative;
  margin-top: -3px;
  width: 1162px;
  height: 3px;
}

.word-4 {
  position: relative;
  margin-top: 27px;
  max-width: 1190px;
  height: 24px;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  line-height: 24px;
  letter-spacing: 0px;
  white-space: nowrap;
  color: #333333;
  font-family: "MicrosoftYaHei";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.layer-2 {
  position: relative;
  margin-top: 8px;
  width: 1014px;
  height: 573px;
}
</style>
